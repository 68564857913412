<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-simple-table>
							<thead>
								<tr><th>ID</th><th>Заголовок</th><th>Статус</th><th>Запланирован</th><th>Опубликован</th><th>Менеджер</th></tr>
							</thead>
							<tbody>
								<tr v-for="(post, i) in posts" :key="i" @click="go(post.pid)">
									<td>{{post.id}}</td>
									<td>{{post.title}}</td>
									<td>{{post.status}}</td>
									<td>{{post.planned_at}}</td>
									<td>{{post.published_at}}</td>
									<td>{{post.created_by}}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import httpClient from '../../services/http.service'

export default {
  name: 'SimplePosts',
  data(){
    return{
      posts: []
    }
  },
  created () {
    httpClient.get('/simple-posts/posts').then(({data})=>{
      this.posts = data
    })
  },
  methods:{
    go(id){
      this.$router.push('/posts/simple-post/'+id)
    }
  }
}
</script>

<style scoped>

</style>
